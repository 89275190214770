import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  max-width: max-content;
  margin: 24px auto 0;
`

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .headline--with-line-design {
    margin-bottom: 12px; // override
  }

  .headline--title {
    font-size: 18px;
    line-height: 20px;
    font-family: ${(props) => props.theme.typography.serif};
  }

  h4 {
    display: block;
    margin-top: 16px;
    margin-bottom: 2px;
    font-family: ${props => props.theme.typography.sansSerif};
    text-transform: uppercase;
    font-weight: 500;
  }

  .award-text {
    font-size: 14px;
  }

  .section-title {
    margin-bottom: 0;

    + .headline--title,
    + .text-center {
      margin-top: 12px;

      @media screen and (min-width: ${(props) =>
    props.theme.responsive.large}) {
        margin-top: 16px;
      }
    }

    + .headline--intro {
      font-style: italic;
    }
  }

  .recipients-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 1fr));
    grid-gap: 12px;
    margin-top: 10px;

    &.no-col-heading {
      margin-top: 16px;
    }

    p {
      font-weight: 500;
      line-height: 1.2;

      + p {
        margin-top: 0px;
      }

      span {
        font-weight: 300;
        font-size: 14px;
        line-height: 1;
      }
    }

    div {
      p {
        line-height: 1.6;

        + p {
          margin-top: 12px;
        }
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .headline--title {
      font-size: 22px;
      line-height: 24px;
    }
  }
`

const ArtistRecognition = () => {
  return (
    <Wrapper className="wrap">
      <section className="section">
        <h3 className="headline--reunion center-align">The Seint Awards</h3>
        <p style={{ marginBottom: '30px' }}>
          Seint is hosting our version of the Academy Awards during LOVE! The Seint Awards will be
          announced LIVE during LOVE, and we need your help to find our top nominees! See the awards
          being given out below and make your nominations <a style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLScTFK_bc26jketnrO5FZCLzgdAiESl6hbrFfqnxrG6f7Y4Asw/viewform">HERE</a> before Monday, January 22, 2024!
        </p>
        <h4>Best Artist Story Reel</h4>
        <p className="award-text">
          No matter what you do, your job is to tell your story. The Best Artist Story award recognizes the
          person who has created the most memorable, impactful, and inspiring Reel to share their journey as a
          Seint Artist and how they got here.
        </p>
        <h4>Ray of Sunshine</h4>
        <p className="award-text">
          When you can’t find the sunshine, this Artist IS the sunshine. This award recognizes an Artist who
          brings joy, positivity, and a ray of sunshine into everything she does, making everyone around her
          happier. This Artist is someone who radiates positivity, uplifts her customers and fellow Artists,
          and consistently embodies the values and culture at Seint.
        </p>
        <h4>Best Makeup Reel</h4>
        <p className="award-text">
          Makeup is meant to enhance natural beauty, not cover it up. The Best Makeup Reel award goes to
          an Artist who has created a Reel that captures the viewer’s attention and draws them into the
          simplicity and power of Seint’s products.
        </p>
        <h4>Hype Girl</h4>
        <p className="award-text">
          When someone believes in you and is cheering you on, everything can change. The Hype Girl award goes
          to an Artist who goes above and beyond to create a sense of excitement in celebrating and bringing
          out the best in others. This Artist’s enthusiastic attitude is contagious and she has a significant
          impact in uplifting the Seint community.
        </p>
        <h4>Funniest Reel</h4>
        <p className="award-text">
          Life is more beautiful with a sense of humor. Learning to laugh at yourself and not take life too
          seriously is one of the greatest gifts you can have. The Funniest Reel award goes to an Artist who
          knows how to find joy and laughter in everyday life, and has created the funniest reel to capture
          that.
        </p>
        <h4>Queen of Culture</h4>
        <p className="award-text">
          The sense of community is greater than any one person. The Queen of Culture award goes to  an Artist
          who has demonstrated exceptional contribution to fostering a positive and inclusive community
          culture. This Artist dedicates her efforts to promote teamwork, collaboration, and a sense of
          belonging among her fellow Artists.
        </p>
        <h4>Best Makeover</h4>
        <p className="award-text">
          The Artist sees what others only catch a glimpse of. Being a Seint Artist means you have an eye for
          beauty and a talent for helping others see the beauty in themselves. The Best Makeover award goes to
          an Artist who had the best makeover and story of helping someone else recognize that they are
          beautiful, with or without makeup.
        </p>
        <h4>Most Interesting Person</h4>
        <p className="award-text">
          What hasn’t this person done?  This award recognizes an Artist who possesses a unique set of
          qualities and experiences that make them a truly captivating individual. The Most Interesting Person
          goes to an Artist who has consistently demonstrated an exceptional level of intrigue, curiosity, and
          a diverse range of interests that make them stand out.
        </p>
      </section>
    </Wrapper>
  )
}

export default ArtistRecognition
